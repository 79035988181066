import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import BlogPosts from './BlogPosts';
import Section from 'components/deprecated/Section';
import Head from 'components/Head';
import WidthProvider from 'components/deprecated/WidthProvider';

import paths from 'constants/paths';

class BlogCategory extends Component {
  render() {
    if (_isEmpty(this.props.posts)) return null;
    return (
      <React.Fragment>
        <Head
          title={`Lettuce Grow Blog - ${get(this.props.category, 'title')}`}
          description={`Lettuce Grow Blog posts categorized under ${get(this.props.category, 'title')}`}
          canonicalRoute={`${paths.BLOG}/${get(this.props.category, 'slug')}`}
        />
        <BlogPosts
          category={this.props.category}
          customPosts={this.props.posts}
          key={this.props.category.title} // force re-mount ensures new cat is not shown on posts before we fetch new cat posts
        />
        {this.props.fields?.section &&
          this.props.fields.sections.map((section) => (
            <Section key={section.sys.id} type={get(section.sys, 'contentType.sys.id')} content={section.fields} />
          ))}
      </React.Fragment>
    );
  }
}

BlogCategory.defaultProps = {
  posts: [],
};

BlogCategory.propTypes = {
  posts: PropTypes.array,
  category: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
  }).isRequired,
};

export default WidthProvider(BlogCategory);
