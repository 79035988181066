import React, { Component } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import marked from 'marked';
import get from 'lodash/get';
import { navigate } from 'gatsby';

import { ButtonText } from 'elements';
import { Media } from 'components';
import Animate from './Animate';

import positions from 'constants/positions';
import sizes from 'constants/sizes';
import buttonTypes from 'constants/buttonTypes';
import icons from 'constants/icons';
import animations from 'constants/animations';

class Callout extends Component {
  constructor() {
    super();
    this.state = {
      hover: false,
    };
  }

  handleHover = (bool) => {
    this.setState({ hover: bool });
  };

  getTitle = () => {
    const mediaTitle = <Media className='lg-callout__media--title' asset={get(this.props, 'mediaTitle.fields')} modifiers='noTransform' />;
    // TODO: abstract truncation to util if needed elsewhere
    const truncatedTitle = `${this.props.title.substring(0, this.props.titleTruncationLimit)}${
      this.props.title.length > this.props.titleTruncationLimit ? '...' : ''
    }`;
    const title = this.props.hasH1Title ? (
      <h1 className='lg-callout__header'>{this.props.titleTruncationLimit ? truncatedTitle : this.props.title}</h1>
    ) : (
      <h5 className='lg-callout__header'>{this.props.titleTruncationLimit ? truncatedTitle : this.props.title}</h5>
    );

    if (this.props.hasBothTitles && !_isEmpty(this.props.mediaTitle) && this.props.title) {
      return (
        <React.Fragment>
          {mediaTitle}
          {title}
        </React.Fragment>
      );
    } else if (!_isEmpty(this.props.mediaTitle)) {
      return mediaTitle;
    } else if (this.props.title) {
      return title;
    }
    return null;
  };

  render() {
    /*
        No cta prop:
            --Media image/header and text body are not clickable links.
            --Button doesn't render.

        cta prop is present & shouldNotLink prop is false (default):
        --The media image/header, the text body, and the button are clickable links.

        cta prop is present & shouldNotLink prop is true:
            --all links (for the media image/header, the text body, and the button) are disabled.
            --this will come up when an entire parent component (e.g. Section) is a link. Don't want links in links.
            --still passing cta as a prop as want to display button with cta linkText (even though button is disabled).
        */

    //section1 includes the media image and/or header
    let section1 = (
      <React.Fragment>
        {!_isEmpty(this.props.mediaContent) ? (
          <Animate trigger={!_isEmpty(this.props.mediaContentHover) && !this.state.hover} animation={animations.EASE}>
            <Media
              className='lg-callout__media--content'
              asset={this.props.mediaContent.fields}
              nativeWidth={this.props.mediaContentParams}
              modifiers='noTransform'
            />
          </Animate>
        ) : null}
        {!_isEmpty(this.props.mediaContentHover) ? (
          <Animate trigger={!_isEmpty(this.props.mediaContent) && this.state.hover} animation={animations.EASE}>
            <Media
              className='lg-callout__media--content lg-callout__media--content-hover'
              asset={this.props.mediaContentHover.fields}
              nativeWidth={this.props.mediaContentParams}
              modifiers='noTransform'
            />
          </Animate>
        ) : null}
        {this.getTitle()}
        {this.props.byline && <p className='lg-callout__byline'>{this.props.byline}</p>}
      </React.Fragment>
    );
    //section2 includes the text body
    let section2 = (
      <div
        className='lg-callout__text'
        dangerouslySetInnerHTML={{
          __html: marked(this.props.body || ''),
        }}
      />
    );

    if (!this.props.shouldNotLink && !this.props.isOnlyButtonLink && !_isEmpty(this.props.cta)) {
      section1 = (
        <Link to={this.props.cta.fields.linkUrl} state={{ state: this.props.cta.fields.linkState }} className='lg-callout__section-1'>
          {section1}
        </Link>
      );
      section2 = (
        <Link to={this.props.cta.fields.linkUrl} state={{ state: this.props.cta.fields.linkState }}>
          {section2}
        </Link>
      );
    }

    return (
      <div
        className={cx(`lg-callout lg-callout--${this.props.textAlign} lg-callout--${this.props.size} ${this.props.className}`, {
          'lg-callout--animated': this.props.isAnimated,
        })}
        onMouseEnter={() => this.handleHover(true)}
        onMouseLeave={() => this.handleHover(false)}
      >
        {section1}
        <div className={`lg-callout__body`}>
          {this.props.body && section2}
          {this.props.children}
        </div>
        {!_isEmpty(this.props.cta) && !!this.props.cta.fields.linkText && (
          <div className='lg-callout__button--container'>
            <ButtonText
              onClick={
                this.props.shouldNotLink ? null : () => navigate(this.props.cta.fields.linkUrl, { state: this.props.cta.fields.linkState })
              }
              label={this.props.cta.fields.linkText}
            />
          </div>
        )}
      </div>
    );
  }
}

Callout.propTypes = {
  title: PropTypes.string,
  mediaTitle: PropTypes.object,
  mediaAccent: PropTypes.object,
  mediaContent: PropTypes.object,
  body: PropTypes.string,
  byline: PropTypes.string,
  cta: PropTypes.object,
  ctaType: PropTypes.oneOf(buttonTypes.ALL),
  ctaIcon: PropTypes.oneOf(icons.ALL),
  ctaIsInverted: PropTypes.bool,
  ctaSize: PropTypes.oneOf(sizes.ALL),
  className: PropTypes.string,
  size: PropTypes.oneOf(sizes.ALL),
  textAlign: PropTypes.oneOf(positions.ALL),
  highlightPosition: PropTypes.oneOf([positions.LEFT, positions.CENTER]),
  isAnimated: PropTypes.bool,
  shouldNotLink: PropTypes.bool,
  isOnlyButtonLink: PropTypes.bool,
  hasH1Title: PropTypes.bool,
  hasBothTitles: PropTypes.bool,
  titleTruncationLimit: PropTypes.number,
  shouldLoadLazy: PropTypes.bool,
};

Callout.defaultProps = {
  title: '',
  body: '',
  byline: '',
  mediaAccent: {},
  mediaContent: {},
  mediaTitle: {},
  cta: {},
  ctaType: buttonTypes.TEXT,
  ctaIcon: icons.CHEVRON_RIGHT,
  ctaIsInverted: false,
  ctaSize: null,
  className: '',
  size: sizes.LARGE,
  textAlign: positions.CENTER,
  highlightPosition: null,
  isAnimated: false,
  shouldNotLink: false,
  isOnlyButtonLink: PropTypes.false,
  hasH1Title: false,
  hasBothTitles: false,
  titleTruncationLimit: null,
  shouldLoadLazy: false,
};

export default Callout;
