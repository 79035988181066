import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { Link, graphql, StaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';

import { Text } from 'elements';
import Head from 'components/Head';
import Page, { Spacer, Menu } from './Blog.styled';

import { useStopLoading, useScrollListener } from 'utils/hooks';

import paths from 'constants/paths';

const MenuLink = ({ linkTo, title, isActive }) => {
  return (
    <Link to={linkTo} className='sub-link'>
      <Text modifiers={['brandFont', 'small', isActive && 'brandColor']} as={isActive ? 'h1' : 'p'} content={title} />
    </Link>
  );
};

MenuLink.propTypes = {
  linkTo: PropTypes.string,
  title: PropTypes.string,
  isActive: PropTypes.bool,
};

const BlogLandingComponent = ({ children, blogCategories = [], fields }) => {
  const isPageScrolled = useScrollListener();
  const isLoading = _isEmpty(blogCategories) || _isEmpty(fields);
  const location = useLocation();
  const currentCategory = location.pathname.match(/resources\/(\S+)/)?.[1];
  useStopLoading(!isLoading);

  const menuLinks = [
    { node: { path: paths.BLOG, title: 'Resources Home', id: 'resoucesHome', additionalLink: true } },
    { node: { path: paths.SEEDLING_LIBRARY, title: 'Seedling Library', id: 'seedlingLibrary', additionalLink: true } },
    ...blogCategories,
    { node: { path: paths.RECIPES, title: 'Recipes', id: 'recipes', additionalLink: true } },
  ];

  if (isLoading) return null;

  return (
    <Page className='lg-blog lg-page'>
      <Head
        title={fields.metaTitle}
        description={fields.metaDescription.metaDescription}
        ogImage={get(fields, 'metaFogImage.file.url')}
        twitterImage={get(fields, 'metaTwitterImage.file.url')}
        canonicalRoute={paths.BLOG}
      />
      <Menu isPageScrolled={isPageScrolled} isInverted={false}>
        {menuLinks.map((link) => {
          const isActive = currentCategory && currentCategory === link.node.slug;
          const linkTo = link.node.additionalLink ? link.node.path : `${paths.BLOG}/${link.node.slug}`;
          return <MenuLink key={link.node.id} linkTo={linkTo} title={link.node.title} isActive={isActive} />;
        })}
      </Menu>
      <Spacer />
      {children}
    </Page>
  );
};

BlogLandingComponent.propTypes = {
  children: PropTypes.node,
  blogCategories: PropTypes.array,
  fields: PropTypes.object,
};

const BlogLanding = ({ children, ...props }) => {
  const categories = graphql`
    query {
      contentfulLandingPage(slug: { eq: "resources" }) {
        ...LandingPageFragment
      }
      allContentfulBlogCategory(sort: { fields: rank }) {
        edges {
          node {
            ...BlogCategoryFragment
          }
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={categories}
      render={(data) => {
        return (
          <BlogLandingComponent fields={data.contentfulLandingPage} blogCategories={data?.allContentfulBlogCategory?.edges} {...props}>
            {children}
          </BlogLandingComponent>
        );
      }}
    />
  );
};

BlogLanding.propTypes = {
  children: PropTypes.node,
};

export default BlogLanding;
