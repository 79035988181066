import { Component } from 'react';
import PropTypes from 'prop-types';

import WidthProvider from './WidthProvider';

import { breakpoints } from 'constants';

// 'render props' function that passes native image height as arg to render func
class LoadedMediaHeight extends Component {
  constructor(props) {
    super(props);
    this.container = null;
    this.image = null;
    this.state = { height: props.defaultHeight };
  }

  componentDidMount() {
    if ((!this.props.backgroundVideoDesktopId && this.props.width >= breakpoints.MOBILE) || this.props.width < breakpoints.MOBILE) {
      this.setImage();
    }
    this.getMediaHeight();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.minHeight !== this.props.minHeight ||
      (prevProps.width < breakpoints.MOBILE && this.props.width >= breakpoints.MOBILE) ||
      (prevProps.width >= breakpoints.MOBILE && this.props.width < breakpoints.MOBILE) ||
      prevProps.url !== this.props.url
    ) {
      if ((!this.props.backgroundVideoDesktopId && this.props.width >= breakpoints.MOBILE) || this.props.width < breakpoints.MOBILE) {
        this.setImage();
      }
      this.getMediaHeight();
    }
  }

  setImage() {
    if (this.props.url) {
      const image = new Image();
      image.src = this.props.url;
      this.image = image;
    }
  }

  getMediaHeight() {
    if (document.getElementById(this.props.backgroundVideoDesktopId) && this.props.width >= breakpoints.MOBILE) {
      this.getVideoHeight();
    } else if (this.image) {
      this.getImageHeight();
    }
  }

  getImageHeight() {
    this.image.onload = () => {
      let height;
      if (!!this.props.minHeight) {
        height = Math.max(((this.image.height / this.image.width) * 100).toFixed(2), this.props.minHeight);
      } else {
        height = ((this.image.height / this.image.width) * 100).toFixed(2);
      }
      this.setState({ height: `${height}vw` });
    };
  }

  getVideoHeight() {
    const video = document.getElementById(this.props.backgroundVideoDesktopId);
    video.addEventListener('loadedmetadata', () => {
      let height;
      if (!!this.props.minHeight) {
        height = Math.max(((video.videoHeight / video.videoWidth) * 100).toFixed(2), this.props.minHeight);
      } else {
        height = ((video.videoHeight / video.videoWidth) * 100).toFixed(2);
      }
      this.setState({ height: `${height}vw` });
    });
  }

  render() {
    return this.props.render(this.state);
  }
}

LoadedMediaHeight.defaultProps = {
  url: '',
  minHeight: '',
  backgroundVideoDesktopId: '',
  defaultHeight: '40vw',
};

LoadedMediaHeight.propTypes = {
  url: PropTypes.string,
  render: PropTypes.func.isRequired,
  minHeight: PropTypes.string, // in vw unit
  backgroundVideoDesktopId: PropTypes.string,
  defaultHeight: PropTypes.string,
};

export default WidthProvider(LoadedMediaHeight);
