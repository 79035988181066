import styled from 'styled-components';
import { containerMixin } from 'theme/Mixins';
import breakpoints from 'theme/Media';

export default styled.div.attrs({ className: 'Blog' })`
  background: ${({ theme }) => theme.farmProject.gray_3};
  .lg-blog-post__inner {
    width: 85%;
    margin: 3rem auto 0;
  }
  @media ${breakpoints.medium_break} {
    .lg-blog-post__inner {
      width: 60%;
    }
  }
  .lg-blog__post--featured {
    position: relative;
    .Card {
      position: absolute;
      bottom: 2rem;
      left: 10%;
      width: 20rem;
      height: auto;
      background: ${({ theme }) => theme.farmProject.gray_3}cc;

      @media ${breakpoints.large_break} {
        top: auto;
        bottom: -3rem;
        left: 50%;
        transform: translate(-27rem, -50%);
      }

      @media ${breakpoints.xLarge_break} {
        transform: translate(-31rem, -50%);
      }
    }
  }
  .lg-blog__post--featured {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 35vw;
    max-height: 600px;
    cursor: pointer;
  }
  .lg-section-text__header {
    .lg-callout {
      display: table;
      margin: 0 auto;
    }
    .lg-media__accent {
      transform: rotate(-110deg);
      left: -50px;
      position: absolute;
      top: -40px;
      .lg-media {
        max-width: 110px;
      }
    }
  }
  .lg-responsive-iframe-wrapper {
    padding: calc(56.25% * 0.5); // 16:9 ratio and multiplied by .5 as that fits properly for desktop and mobile
  }
`;
export const Spacer = styled.div.attrs({ className: 'BlogSpacer' })`
  height: 6.5rem;
`;
export const DiscoverMoreWrapper = styled.div.attrs({ className: 'BlogDiscoverMore' })`
  text-align: center;

  > .Title {
    font-size: 2.25rem;
    line-height: 1.2;
    letter-spacing: 2px;
    margin: 4rem 0 0;

    @media ${breakpoints.large_break} {
      margin: 6rem 0 4rem;
    }
  }
`;
export const BlogPostsWrapper = styled.div.attrs({ className: 'BlogPostsWrapper' })`
  ${containerMixin};
  margin: 2.5rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .BlogPosts__inner {
    display: grid;
    grid-template-columns: 1;
    grid-gap: 2rem 2rem;
    margin-bottom: 3rem;
  }
  @media ${breakpoints.medium_break} {
    .BlogPosts__inner {
      grid-template-columns: repeat(2, minmax(15rem, 1fr));
    }
  }
  @media ${breakpoints.large_break} {
    width: 54rem;

    .BlogPosts__inner {
      max-width: 62rem;
      grid-template-columns: repeat(3, minmax(15rem, 1fr));
      grid-gap: 7rem 2rem;

      .Card {
        .Image {
          height: 10rem;
        }
      }
    }
  }

  @media ${breakpoints.xLarge_break} {
    width: 62rem;
  }
`;

export const FeaturedBlogPostWrapper = styled.div.attrs({ className: 'FeaturedBlogPostWrapper' })`
  display: none;

  @media ${breakpoints.medium_break} {
    display: block;
  }
`;

export const Menu = styled.ul.attrs({ className: 'BlogMenu' })`
  width: 100%;
  background: ${({ theme }) => theme.farmProject.gray_3};
  border-bottom: 1px solid ${({ theme }) => theme.farmProject.gray_1};
  margin: 0;
  padding: 1.5rem 10%;
  display: flex;
  justify-content: flex-start;
  position: fixed;
  left: 0;
  right: 0;
  top: ${(props) => (props.isPageScrolled ? '3.5rem' : 'unset')};
  z-index: 2;
  overflow-x: auto;
  .sub-link {
    margin: 0 0.9rem;
    p,
    h1 {
      white-space: nowrap;
      font-size: 0.75rem;
    }
    &:first-of-type {
      margin-left: 0;
    }
  }
  h1 {
    margin-top: 0;
  }
  @media ${breakpoints.large_break} {
    justify-content: center;
  }
  @media ${breakpoints.small_break} {
    .sub-link {
      &:last-of-type {
        padding-right: 3em;
      }
    }
  }
`;

export const Post = styled.div`
  min-height: 100vh; // display white space while loading content
  .lg-blog-post__inner {
    margin: 0 auto;
    width: 100%;
    max-width: 33.5rem;
    padding: 0 2rem;

    @media ${breakpoints.large_break} {
      margin: 3rem auto 0;
      width: 60%;
      max-width: 60rem;
      padding: 0;

      .accessory-image {
        max-width: 50%;
      }
    }

    .lg-blog-post__heading {
      margin-bottom: 2em;

      .Title {
        font-size: 2.25rem;
        line-height: 1.2;
        letter-spacing: 2px;
        margin: 0.25rem 0;
      }

      @media ${breakpoints.large_break} {
        .Title {
          font-size: 3.125rem;
        }
      }
    }

    .lg-blog-post__bullet {
      margin: 0 0.5em;
      height: 25px;
      display: inline-block;
      vertical-align: sub;
    }
    .lg-media-caption {
      margin-top: -3em;
      margin-bottom: 2em;
    }
    .lg-blog__partner-logo.Image {
      width: 100px;
      height: 100px;
      img {
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .lg-carousel.slick-slider {
    margin-top: 0;
    .lg-cards__card {
      height: 100%;
    }
  }
  .lg-callout {
    display: table;
    margin: 0 auto 1em;
  }
  .lg-media__accent {
    transform: rotate(-110deg);
    left: -50px;
    position: absolute;
    top: -40px;
    .lg-media {
      max-width: 110px;
    }
  }
  .lg-blog-post__body {
    .Image {
      width: 100%;
      height: auto;
      margin: 1rem 0;
      img {
        position: initial;
        transform: unset;
      }
    }
  }

  @include richText();

  @media ${breakpoints.medium_break} {
    .lg-blog-post__body {
      margin: 0;
    }
  }
`;
