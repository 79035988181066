import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { navigate } from 'gatsby';

import Callout from './Callout';
import SharedText from './elements/Text';

import positions from 'constants/positions';
import sizes from 'constants/sizes';

class Text extends Component {
  handlePrimaryButtonClick = () => {
    navigate(this.props.content.primaryButton.fields.linkUrl);
  };
  render() {
    return (
      <div className={cx('lg-section-text', this.props.className)}>
        {(!!this.props.content?.title || !!this.props.content?.mediaTitle || !!this.props.content?.mediaContent) && (
          <div className='lg-section-text__header'>
            <Callout
              textAlign={this.props.helpers.textAlign || positions.CENTER}
              title={this.props.content.title}
              mediaAccent={this.props.content.mediaAccent}
              mediaTitle={this.props.content.mediaTitle}
              mediaContent={this.props.content.mediaContent}
              highlightPosition={this.props.helpers.highlightPosition || positions.CENTER}
              size={sizes.LARGE}
            />
          </div>
        )}
        {!!this.props.content?.headline && (
          <SharedText className='lg-section-text__byline' isBold>
            {this.props.content.headline}
          </SharedText>
        )}
        {!!this.props.content?.body && (
          <SharedText asHTML className='lg-section-text__body'>
            {this.props.content.body}
          </SharedText>
        )}
        {!!this.props.content?.body2 && (
          <SharedText className='lg-section-text__body' asHTML>
            {this.props.content.body2}
          </SharedText>
        )}
      </div>
    );
  }
}

Text.defaultProps = {
  helpers: {},
};

Text.propTypes = {
  content: PropTypes.object.isRequired,
  helpers: PropTypes.shape({
    highlightPosition: PropTypes.oneOf([positions.LEFT, positions.CENTER]),
    textAlign: PropTypes.oneOf(positions.ALL),
  }),
};

export default Text;
