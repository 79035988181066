import React from 'react';
import { graphql } from 'gatsby';

import BlogLanding from 'staticPages/blog/BlogLanding';
import BlogCategory from 'staticPages/blog/BlogCategory';

const BlogPostsLanding = ({ data }) => {
  return (
    <BlogLanding>
      <BlogCategory posts={data?.allContentfulBlogPost?.edges} category={data?.contentfulBlogCategory} />
    </BlogLanding>
  );
};

export const query = graphql`
  query($slug: String!) {
    allContentfulBlogPost(filter: { postCategories: { elemMatch: { slug: { eq: $slug } } } }) {
      edges {
        node {
          ...BlogPostFragment
        }
      }
    }
    contentfulBlogCategory(slug: { eq: $slug }) {
      ...BlogCategoryFragment
    }
  }
`;

export default BlogPostsLanding;
