import React, { Component } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import get from 'lodash/get';

import Animate from './Animate';
import Callout from './Callout';
import WidthProvider from './WidthProvider';

import LoadedMediaHeight from './LoadedMediaHeight';
import { Media } from 'components';

import { breakpoints, positions, icons } from 'constants';

class Standard extends Component {
  getStandard = (height, isFullLink) => {
    const mediaImage =
      this.props.width < breakpoints.MOBILE && !_isEmpty(this.props.content.mediaContentMobile)
        ? this.props.content.mediaContentMobile
        : this.props.content.mediaContent;
    return (
      <div className={cx(`lg-standard lg-section--${this.props.content.orientation}`, this.props.className)}>
        <div
          className={cx({ 'lg-standard--inner__background': !_isEmpty(this.props.content.mediaBackground) })}
          ref={(node) => (this.innerBackground = node)}
          style={{
            backgroundImage:
              this.props.content.mediaBackground && (this.props.width >= breakpoints.MOBILE || this.props.helpers.hasMobileBackground)
                ? `url(${this.props.content.mediaBackground.fields.file.url})`
                : null,
            height: this.props.width >= breakpoints.MOBILE && this.props.content.mediaBackground ? height : null,
          }}
        >
          <div className={`lg-standard__inner lg-section__inner`} ref={(node) => (this.inner = node)}>
            {this.props.content.orientation === positions.LEFT && (
              <Animate trigger={this.props.isInViewport} runOnTransition>
                <Callout
                  textAlign={positions.LEFT}
                  title={this.props.content.title}
                  mediaTitle={this.props.content.mediaTitle}
                  mediaAccent={this.props.content.mediaAccent}
                  body={this.props.content.body}
                  cta={this.props.helpers.hasOnlySideButtonAndImageLinks ? null : this.props.content.callToAction}
                  highlightPosition={positions.CENTER}
                  shouldNotLink={this.props.helpers.hasOnlySideButtonAndImageLinks || isFullLink}
                  ctaType={this.props.helpers.calloutHelpers.ctaType}
                  ctaIcon={this.props.helpers.calloutHelpers.ctaIcon}
                  ctaIsInverted={this.props.helpers.calloutHelpers.ctaIsInverted}
                  ctaSize={this.props.helpers.calloutHelpers.ctaSize}
                  isOnlyButtonLink={this.props.helpers.calloutHelpers.isOnlyButtonLink}
                >
                  {this.props.content.customBody}
                </Callout>
              </Animate>
            )}
            {this.props.width < breakpoints.MOBILE && !_isEmpty(this.props.content.card) && (
              <div className='lg-standard__card'>{this.props.content.card}</div>
            )}
            {this.props.helpers.hasOnlySideButtonAndImageLinks &&
            !_isEmpty(this.props.content.callToAction) &&
            !!this.props.content.callToAction.fields.linkText ? (
              <div className='lg-standard__media-button--container'>
                {!_isEmpty(mediaImage) && (
                  <Media
                    href={this.props.content.callToAction?.fields?.linkUrl}
                    asset={mediaImage.fields}
                    className='lg-standard__main-image'
                    modifiers='noTransform'
                  />
                )}
              </div>
            ) : (
              !_isEmpty(mediaImage) && (
                <Media
                  href={this.props.content.callToAction?.fields?.linkUrl}
                  asset={mediaImage.fields}
                  className='lg-standard__main-image'
                  modifiers='noTransform'
                />
              )
            )}
            {this.props.width >= breakpoints.MOBILE && !_isEmpty(this.props.content.card) && (
              <div className='lg-standard__card'>{this.props.content.card}</div>
            )}
            {this.props.content.orientation === positions.RIGHT && (
              <Animate trigger={this.props.isInViewport} runOnTransition>
                <Callout
                  textAlign={positions.LEFT}
                  title={this.props.content.title}
                  mediaTitle={this.props.content.mediaTitle}
                  mediaAccent={this.props.content.mediaAccent}
                  body={this.props.content.body}
                  cta={this.props.helpers.hasOnlySideButtonAndImageLinks ? null : this.props.content.callToAction}
                  highlightPosition={positions.CENTER}
                  shouldNotLink={this.props.helpers.hasOnlySideButtonAndImageLinks || isFullLink}
                  ctaType={this.props.helpers.calloutHelpers.ctaType}
                  ctaIcon={this.props.helpers.calloutHelpers.ctaIcon}
                  ctaIsInverted={this.props.helpers.calloutHelpers.ctaIsInverted}
                  ctaSize={this.props.helpers.calloutHelpers.ctaSize}
                  isOnlyButtonLink={this.props.helpers.calloutHelpers.isOnlyButtonLink}
                >
                  {this.props.content.customBody}
                </Callout>
              </Animate>
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const isFullLink =
      !_isEmpty(this.props.content.callToAction) &&
      _isEmpty(this.props.content.mediaContent) &&
      !this.props.helpers.hasOnlySideButtonAndImageLinks &&
      !this.props.helpers.calloutHelpers.isOnlyButtonLink;
    const mediaImage = this.props.width < breakpoints.MOBILE ? null : get(this.props.content.mediaBackground, 'fields.file.url');
    const content = this.inner && this.inner.querySelector('.lg-callout');
    const backgroundStyle = !!this.innerBackground && window.getComputedStyle(this.innerBackground);
    const paddingTop = this.innerBackground && backgroundStyle ? parseInt(backgroundStyle.paddingTop) : 0;
    const innerHeight = content ? content.offsetHeight : 0;
    const minHeight = (((innerHeight + paddingTop * 2) / this.props.width) * 100).toFixed(2);
    if (isFullLink) {
      return (
        <Link to={this.props.content.callToAction.fields.linkUrl}>
          {<LoadedMediaHeight url={mediaImage} minHeight={minHeight} render={({ height }) => this.getStandard(height, isFullLink)} />}
        </Link>
      );
    } else {
      return <LoadedMediaHeight render={({ height }) => this.getStandard(height, isFullLink)} minHeight={minHeight} url={mediaImage} />;
    }
  }
}

Standard.defaultProps = {
  helpers: { hasMobileBackground: false, hasOnlySideButtonAndImageLinks: false, calloutHelpers: {} },
};

Standard.propTypes = {
  content: PropTypes.object.isRequired,
  helpers: PropTypes.shape({
    hasMobileBackground: PropTypes.bool,
    hasOnlySideButtonAndImageLinks: PropTypes.bool,
    calloutHelpers: PropTypes.object,
  }),
};

export default WidthProvider(Standard);
