import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PropTypes from 'prop-types';

import animations from 'constants/animations';

class Animate extends Component {
  render() {
    if (this.props.runOnTransition) {
      return (
        <TransitionGroup component={null} className={this.props.animation} appear exit={false}>
          <CSSTransition key={this.props.trigger} classNames={this.props.animation} timeout={0}>
            {React.cloneElement(this.props.children, { isAnimated: true })}
          </CSSTransition>
        </TransitionGroup>
      );
    } else {
      return (
        <CSSTransition
          appear
          unmountOnExit={this.props.unmountOnExit}
          in={!!this.props.trigger}
          exit={false}
          classNames={this.props.animation}
          timeout={0}
        >
          {React.cloneElement(this.props.children, { isAnimated: true })}
        </CSSTransition>
      );
    }
  }
}

class AnimateGroup extends Component {
  render() {
    return (
      <TransitionGroup component={null} className={this.props.animation} appear>
        {React.Children.map(this.props.children, (child, idx) => {
          return (
            <CSSTransition key={idx} classNames={this.props.animation} timeout={0}>
              {React.cloneElement(child, { isAnimated: true })}
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    );
  }
}

Animate.defaultProps = {
  animation: animations.FADE_SCROLL,
  unmountOnExit: false,
  runOnTransition: false,
};

Animate.propTypes = {
  trigger: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired, // the change of this prop triggers the animation to run
  animation: PropTypes.oneOf(animations.ALL),
  unmountOnExit: PropTypes.bool,
  runOnTransition: PropTypes.bool,
};

Animate.Group = AnimateGroup;

export default Animate;
