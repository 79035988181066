import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import get from 'lodash/get';

import Callout from './Callout';
import Animate from './Animate';

import positions from 'constants/positions';

class Reviews extends Component {
  render() {
    const review = get(this.props.content, 'reviews[0]');
    return (
      <div className={cx('lg-reviews', this.props.className)}>
        <div className='lg-reviews__inner lg-section__inner'>
          <Animate trigger={this.props.isInViewport} runOnTransition>
            <Callout
              textAlign={this.props.helpers.calloutPosition || positions.LEFT}
              title={this.props.content.title}
              mediaTitle={this.props.content.mediaTitle}
              body={this.props.content.body}
              cta={this.props.content.callToAction}
              highlightPosition={this.props.helpers.highlightPosition || positions.CENTER}
              hasBothTitles={this.props.helpers.hasBothCalloutTitles}
            />
          </Animate>
          {!_isEmpty(this.props.content.carousel) && this.props.content.carousel}
          {!_isEmpty(review) && (
            <Callout
              textAlign={positions.LEFT}
              title={review.fields.title}
              body={review.fields.body}
              cta={review.fields.callToAction}
              mediaContent={review.fields.mediaContent.fields}
            />
          )}
        </div>
      </div>
    );
  }
}

Reviews.defaultProps = {
  helpers: {}, //defaultProps doesn't work well with object properties - hence the || in render
};

Reviews.propTypes = {
  content: PropTypes.object.isRequired,
  helpers: PropTypes.shape({
    calloutPosition: PropTypes.oneOf(positions.ALL),
    highlightPosition: PropTypes.oneOf([positions.LEFT, positions.CENTER]),
    hasBothCalloutTitles: PropTypes.bool,
  }),
};

export default Reviews;
