import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import cx from 'classnames';

import Standard from './Standard';
import Text from './Text';
import Reviews from './Reviews';

import { sectionTypes } from 'constants';

class Section extends Component {
  render() {
    let Component;
    switch (this.props.type) {
      case sectionTypes.TEXT:
        Component = Text;
        break;
      case sectionTypes.REVIEWS:
        Component = Reviews;
        break;
      case sectionTypes.STANDARD:
      default:
        Component = Standard;
    }

    return (
      <Component
        content={this.props.content}
        helpers={this.props.helpers}
        orientation={this.props.content.orientation}
        className={cx('lg-section', this.props.className, {
          'lg-section--background': !_isEmpty(this.props.content.mediaBackground),
          'lg-section--skewed': this.props.content.sectionType === 'skewed',
        })}
        isInViewport={this.props.isInViewport} // from HOC
        isInitiallyInViewport={this.props.isInitiallyInViewport} // from HOC
        width={this.props.width} // from HOC
        device={this.props.device}
        defaultHeight={this.props.defaultHeight}
      />
    );
  }
}

Section.defaultProps = {
  content: {},
  className: '',
};

Section.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    titleAccentImage: PropTypes.object,
    body: PropTypes.string,
    callToAction: PropTypes.object, // need to change
    link: '', // need to group with callto action
  }),
  helpers: PropTypes.object, //non-content props for component to use
  type: PropTypes.string.isRequired,
  width: PropTypes.number,
  className: PropTypes.string,
};

export default Section;
